<template>
	<!-- <div id="userInfo" class="el-content">
		<a-form ref="form" label-width="80px">
			<div class="kd-user-li">
				<div class="kd-user-li-title">头像</div>
				<div class="kd-user-li-head">
					<img class="kd-pic-url" v-if="info.pic_url" :src="info.pic_url" alt="" />
					<img class="kd-pic-url" v-else src="../../../assets/img/moren.png" alt="" />
				</div>

				<div class="kd-user-li-opration">
					<kd-img-select :custom="true" @success=" e => { info.pic_url = e; } " ></kd-img-select>
				</div>
			</div>
			<div class="kd-user-li">
				<div class="kd-user-li-title">用户</div>
				<div class="kd-user-li-head">
					<a-input v-if="update_type == 1" v-model:value="info.name"></a-input>
					<span v-else>{{ info.name }}</span>
				</div>
				<div class="kd-user-li-opration"><a-button v-has="{ action: 'sass_user_edit' }" @click="update_type = 1" type="link">修改</a-button></div>
			</div>

			<div class="kd-user-li">
				<div class="kd-user-li-title">手机号</div>
				<div class="kd-user-li-head">
					<a-input v-if="update_type == 2" v-model:value="info.mobile"></a-input>
					<span v-else>{{ info.mobile }}</span>
				</div>
				<div class="kd-user-li-opration"><a-button v-has="{ action: 'sass_user_edit' }" @click="update_type = 2" type="link">修改</a-button></div>
			</div>
			<div class="kd-user-li">
				<div class="kd-user-li-title">密码</div>
				<div class="kd-user-li-head"><span>******</span></div>
				<div class="kd-user-li-opration"><a-button v-has="{ action: 'sass_user_update_pwd' }" @click="passwordDialog = true" type="link">修改</a-button></div>
			</div>
			<div class="kd-user-li">
				<div class="kd-user-li-title">可创建小程序个数</div>
				<div class="kd-user-li-head">
					<span>{{ info.mini_limit }}</span>
				</div>
			</div>
			<div class="kd-user-li">
				<div class="kd-user-li-title">邮箱</div>
				<div class="kd-user-li-head">
					<a-input v-if="update_type == 3" v-model:value="info.email"></a-input>
					<span v-else>{{ info.email }}</span>
				</div>
				<div class="kd-user-li-opration"><a-button v-has="{ action: 'sass_user_edit' }" @click="update_type = 3" type="link">修改</a-button></div>
			</div>
			<div class="kd-user-li">
				<div class="kd-user-li-title">最后登录时间</div>
				<div class="kd-user-li-head">
					<span>{{ info.login_time }}</span>
				</div>
			</div>
			<div class="kd-user-li">
				<div class="kd-user-li-title">最后登录IP</div>
				<div class="kd-user-li-head">
					<span>{{ info.last_login_ip }}</span>
				</div>
			</div>
			<div class="kd-user-li">
				<div class="kd-user-li-title">到期时间</div>
				<div class="kd-user-li-head">
					<span v-if="info.is_permanent == 1">永久</span>
					<span v-else>{{ info.validity_time }}</span>
				</div>
			</div>

			<a-form-item style="margin-left: 10%;">
				<a-space>
					<a-button v-has="{ action: 'sass_user_edit' }" type="primary" @click="saveUserInfo">保存</a-button>
					<a-button @click="$router.go(-1)">返回</a-button>
				</a-space>
			</a-form-item>
		</a-form>
		<a-modal v-model:visible="passwordDialog" title="修改密码" width="600px" @ok="savePassWord" @cancel="passwordDialog = false">
			<a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
				<a-form-item label="新密码"><a-input v-model:value="passwordForm.password"></a-input></a-form-item>
				<a-form-item label="再次新密码"><a-input v-model:value="passwordForm.re_password"></a-input></a-form-item>
			</a-form>
		</a-modal>
	</div> -->
    <div class="agency-info el-content">
        <a-button class="back-btn" @click="$router.go(-1)">返回</a-button>
        <div class="agency-info-title">基本信息</div>
        <a-row :gutter="[48,48]" v-if="info">
            <a-col :span="2">
                <div class="ai-avartar">
                    <img v-if="info.pic_url" :src="info.pic_url " alt="">
                    <img v-else src="@/assets/img/moren.png" alt="">
                    <div class="ai-avartar-update" @click="showUpdate(4)">修改</div>
                </div>
            </a-col>
            <a-col :md="11" :lg="11" :xl="9" :xxl="8">
                
                <div class="ai-item">
                    <div class="ai-item-title">昵称：</div>
                    <div class="ai-item-val">{{ info.name }}</div>
                    <a-button class="ai-update-btn" type="link" @click="showUpdate(1)">修改</a-button>
                </div>
                <div class="ai-item">
                    <div class="ai-item-title">密码：</div>
                    <div class="ai-item-val">******</div>
                    <a-button class="ai-update-btn" type="link" @click="showUpdate(6)">修改</a-button>
                </div>
                <div class="ai-item">
                    <div class="ai-item-title">手机号：</div>
                    <div class="ai-item-val">{{ info.mobile }}</div>
                    <a-button class="ai-update-btn" type="link" @click="showUpdate(2)">修改</a-button>
                </div>
                <div class="ai-item">
                    <div class="ai-item-title">邮箱：</div>
                    <div class="ai-item-val">{{ info.email }}</div>
                    <a-button class="ai-update-btn" type="link"  @click="showUpdate(3)">修改</a-button>
                </div>
                <div class="ai-item">
                    <div class="ai-item-title">地址：</div>
                    <div class="ai-item-val">{{ info.address }}</div>
                    <a-button class="ai-update-btn" type="link" @click="showUpdate(5)">修改</a-button>
                </div>
            </a-col>
            <a-col :md="11" :lg="11" :xl="9" :xxl="8">
                <div class="ai-item" >
                    <div class="ai-item-title">最后登录：</div>
                    <div class="ai-item-val">{{ info.login_time }}</div>
                </div>
                <div class="ai-item">
                    <div class="ai-item-title">最后登录IP：</div>
                    <div class="ai-item-val">{{ info.last_login_ip }}</div>
                </div>
                <div class="ai-item">
                    <div class="ai-item-title">到期时间：</div>
                    <div class="ai-item-val">
                        <span v-if="info.is_permanent == 1">永久</span>
					    <span v-else>{{ info.validity_time }}</span>
                    </div>
                </div>
                <div class="ai-item">
                    <div class="ai-item-title">可创建农场：</div>
                    <div class="ai-item-val">{{ info.mini_limit }} 个</div>
                </div>
                <div class="ai-item">
                    <div class="ai-item-title">绑定微信：</div>
                    <div class="ai-item-val">{{ info.openid ?'已绑定':'未绑定'}}</div>
                </div>
            </a-col>
        </a-row>

        <a-modal v-model:visible="userState.show" 
            title="修改信息" 
            width="600px" 
            @ok="saveAgency" 
            @cancel="userState.show = false"
        >
			<a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
				<a-form-item label="昵称" v-if="userState.update_type == 1">
                    <a-input v-model:value="userState.form.name"></a-input>
                </a-form-item>
                <a-form-item label="手机号" v-if="userState.update_type == 2">
                    <a-input v-model:value="userState.form.mobile"></a-input>
                </a-form-item>
                <a-form-item label="邮箱" v-if="userState.update_type == 3">
                    <a-input v-model:value="userState.form.email"></a-input>
                </a-form-item>
                <a-form-item label="地址" v-if="userState.update_type == 5">
                    <a-input v-model:value="userState.form.address"></a-input>
                </a-form-item>
                <a-form-item label="头像" v-if="userState.update_type == 4">
                    <kd-img-select :src="userState.form.pic_url" @success=" e => userState.form.pic_url = e "></kd-img-select>
                </a-form-item>
                <div v-if="userState.update_type == 6 && userState.pwdForm">
                    <a-form-item label="新密码">
                        <a-input v-model:value="userState.pwdForm.password"></a-input>
                    </a-form-item>
                    <a-form-item label="再次新密码">
                        <a-input v-model:value="userState.pwdForm.re_password"></a-input>
                    </a-form-item>
                </div>
			</a-form>
		</a-modal>

    </div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import { useRoute } from 'vue-router';
import userModel from '@/api/user';
import authModel from '@/api/saas/auth';
export default {
	setup(){
		const state = reactive({
			user_id: 0,
			info: [],
			update_type: 0, //1名称 2电话 3邮箱
			passwordDialog: false,
			passwordForm: {
				password: '',
				re_password: ''
			}
		})
		const opt = useRoute().query
        getUserDetail()

		function getUserDetail(){
            userModel.getUserDetail(opt.user_id).then(res=>state.info = res)
        }

        let userState = useEditUser({state,getUserDetail})

		// function saveUserInfo(){
		// 	let data = {
		// 		pic_url: state.info.pic_url,
		// 		name: state.info.name,
		// 		mobile: state.info.mobile,
		// 		email: state.info.email,
		// 		user_id: state.info.user_id,
		// 		type: 1,
		// 		rank: 3,
		// 		scene: 'edit'
		// 	}
		// 	authModel.addMerchantUser(data,()=>{})
		// }

		// function savePassWord(){
		// 	let data = {
		// 		user_id: state.info.user_id,
		// 		scene: 'password',
		// 		password: state.passwordForm.password,
		// 		re_password: state.passwordForm.re_password,
		// 		rank: 3,
		// 		type: 1
		// 	}
		// 	authModel.addMerchantUser(data,()=>state.passwordDialog = false)
		// }
		return{
			...toRefs(state),
            ...userState
		}
	}
}

function useEditUser(obj){
    let userState = reactive({
        show:false,
        update_type:1,      //
        pwdForm:null,
        form:null
    })

    function showUpdate(type){
        userState.update_type = type
        
        if( type == 6 ){    //修改密码
            userState.pwdForm = {
                password: '',
                re_password: ''
            }
        }else{
            userState.form = {
                name:obj.state.info.name,
                mobile:obj.state.info.mobile,
                email:obj.state.info.email,
                address:obj.state.info.address,
                pic_url:obj.state.info.pic_url,
            }
        }
        userState.show = true
    }

    function saveAgency(){
        let data = {
            user_id: obj.state.info.user_id,
            type: 1,
            rank: 3,
            scene: userState.update_type == 6 ? 'password':'edit'
        }

        //修改基本信息
        if( data.scene == 'edit'){
            data.pic_url = userState.form.pic_url
            data.name = userState.form.name
            data.mobile = userState.form.mobile
            data.email = userState.form.email
            data.address = userState.form.address
        }

        //修改密码
        if( data.scene == 'password' ){
            data.password = userState.pwdForm.password
			data.re_password = userState.pwdForm.re_password
        }

        authModel.addMerchantUser(data,()=>{
            userState.show = false
            obj.getUserDetail() 
        })
    }
    
    return{ userState ,saveAgency,showUpdate } 
}
</script>

<style lang="scss">
.agency-info{

    &-title{
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 24px;
    }

    .ai-avartar{
        width: 80px;
        height: 80px;
        border-radius: 50%;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        img{
            width: 80px;
            height: 80px;
            border-radius: 50%;
        }

        &-update{
            width: 100%;
            text-align: center;
            background: rgba($color: #000000, $alpha: .5);
            color: #fff;
            display: inline-block;
            position: absolute;
            bottom: 0;
            font-size: 12px;
            height: 24px;
            line-height: 24px;
            left: 0;
        }
    }  

    .ai-item{
        width: 100%;
        display: flex;
        height: 48px;
        align-items: center;
        border-bottom: 1px solid #f4f4f4;
        position: relative;

        &-title{
            width: 100px;
            color: #666;
            font-size: 12px;
        }

        &-val{
            font-weight: bold;
            font-size: 16px;
        }
    }

    .ai-update-btn{
        position: absolute;
        right: 0;
    }

    .back-btn{
        position: absolute;
        right: 24px;
        top: 24px;
    }
}

.kd-user-li {
	width: 100%;
	height: 60px;
	line-height: 60px;
	display: flex;
	font-size: 14px;
	color: #868686;
	border-bottom: 1px solid #f1eded;

	&-title {
		width: 10%;
	}
	&-head {
		width: 50%;
	}
	&-opration {
		width: 40%;
		text-align: right;
		color: #0074d9;
		cursor: pointer;
	}
}
.kd-pic-url {
	width: 50px;
	height: 50px;
	border-radius: 50%;
}
</style>
